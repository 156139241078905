AOS.init();
var slider = tns({
  container: '.commnit-slider',
  items: 1,
  gutter: 10,
  slideBy: 'page',
  autoplay: true,
  nav: false,
  lazyload: true,
  mouseDrag: true,
  loop: true,
  controlsContainer: "#bb-slider-controls",
  autoplayButtonOutput: false,
  responsive: {
    640: {
      edgePadding: 20,
      gutter: 20,
      items: 2
    },
    700: {
      gutter: 30
    },
    900: {
      items: 4
    }
  }
});
const zlButton = document.querySelector('.zalo-button a');
const zaQr = document.querySelector('.zalo-qr .zl-img');
const qrclose = () => {
  zaQr.style.display = 'none'
}
const togglestyle = () => {
  if (zaQr.style.display === 'none') {
    zaQr.style.display = 'block';
  } else {
    zaQr.style.display = 'none'
  }
}
document.querySelector('.zalo-qr .zl-img .close').addEventListener('click', qrclose)
zlButton.addEventListener('click', togglestyle)
var app = function () {
  var body = undefined;
  var menu = undefined;
  var menuItems = undefined;
  var init = function init() {
    body = document.querySelector('body');
    menu = document.querySelector('.menu-icon');
    menuItems = document.querySelectorAll('.nav__list-item');
    applyListeners();
  };
  var applyListeners = function applyListeners() {
    menu.addEventListener('click', function () {
      return toggleClass(body, 'nav-active');
    });
  };
  var toggleClass = function toggleClass(element, stringClass) {
    if (element.classList.contains(stringClass)) element.classList.remove(stringClass); else element.classList.add(stringClass);
  };
  init();
}();
const links = document.querySelectorAll(".hover-target");

for (const link of links) {
  link.addEventListener("click", clickHandler);
}
function clickHandler(e) {
  e.preventDefault();
  const href = this.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop;
  scroll({
    top: offsetTop,
    behavior: "smooth"
  });
}
function trackScroll() {
  var scrolled = window.pageYOffset;
  var coords = document.documentElement.clientHeight;

  if (scrolled > coords) {
    goTopBtn.classList.add('back_to_top-show');
  }
  if (scrolled < coords) {
    goTopBtn.classList.remove('back_to_top-show');
  }
}

function backToTop() {
  if (window.pageYOffset > 0) {
    window.scrollBy(0, -80);
    setTimeout(backToTop, 0);
  }
}

var goTopBtn = document.querySelector('.back_to_top');

window.addEventListener('scroll', trackScroll);
goTopBtn.addEventListener('click', backToTop);

